import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageGrid = makeShortcode("ImageGrid");
const Soundcloud = makeShortcode("Soundcloud");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introspect`}</h1>
    <p>{`Introspect is an experimental live album recorded at Burning Man by San Francisco-based producer Tōsh & guitarist Nicholas Paul.`}</p>
    <p>{`The concert recordings were captured at first light of Burning Man 02022 on the Robot Heart bus, followed by performances in the PlayAlchemist pyramid and the Maxa camp lounge.`}</p>
    <p>{`The album was released on vinyl in fall 2023. In the spirit of decommodification, the vinyl is available as a gift - ask us for a copy :)`}</p>
    <ImageGrid src1="https://res.cloudinary.com/studiocollective/image/upload/v1687814110/Studio%20Collective/Live%20Album/10mb%20squares/Back_vpy3os.png" src2="https://res.cloudinary.com/studiocollective/image/upload/v1687814110/Studio%20Collective/Live%20Album/10mb%20squares/Cover_aqklkg.png" src4="https://res.cloudinary.com/studiocollective/image/upload/v1687814115/Studio%20Collective/Live%20Album/10mb%20squares/Inside_L_gsdbg8.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1687814109/Studio%20Collective/Live%20Album/10mb%20squares/Innersleeve_A_ylxrcr.png" src6="https://res.cloudinary.com/studiocollective/image/upload/v1687814116/Studio%20Collective/Live%20Album/10mb%20squares/Innersleeve_B_bvhnk9.png" src5="https://res.cloudinary.com/studiocollective/image/upload/v1687814109/Studio%20Collective/Live%20Album/10mb%20squares/Inside_R_qncmwp.png" mdxType="ImageGrid" />
    <h2>{`Music`}</h2>
    <Soundcloud playlistId="1585250455" token="0ZlmLjGJ8t0" height="500" mdxType="Soundcloud" />
    <h2>{`Album Concept`}</h2>
    <p>{`Burning Man exists to challenge our understanding of society and of ourselves. To acknowledge our imperfections and observe our inner child. To introspect, burn down, and rebuild. To break the barrier between audience and actor.`}</p>
    <p>{`Introspect embodies this ethos in music, striking against expectations of our modern and mechanical world. It is raw, improvised and evolving. It bridges division between studio and stage.`}</p>
    <p>{`The album was produced with studio recording gear brought out to the Black Rock Desert. Arrangement, guitar, and synth layers were improvised on top of backing samples recorded in Tōsh’s mission district studio. The concept was inspired by philosophies of Sebastian Mullaert's `}<a parentName="p" {...{
        "href": "https://www.circleof.live"
      }}>{`Circle of Live`}</a>{` and the genres of Indian classical, techno, and jazz.`}</p>
    <p>{`”Jazz is not a style, it's a process. It's one minute's music in one minute's time.” — Bill Evans`}</p>
    <ImageGrid src1="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/157F81BF-8EB8-49AE-9383-0CA70A714904_zcbutn.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1665459485/Studio%20Collective/Robot%20Heart%20Pics/7A5F02E1-CE3E-437A-9C57-B06795FB04B7_ccnbpy.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/A7B175B5-20C2-4FFA-A36A-0984FA11D0B6_jyhvdr.jpg" src4="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/BCFE6135-EADE-4BC6-8784-F06E1C1D4671_vfywy3.jpg" src5="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/44F4ABB4-932D-47F1-806B-3B24107C499A_2_qruypo.jpg" src6="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/C2225B86-6E7D-49D4-ACDC-6F9F1A76464F_ivjtqx.jpg" mdxType="ImageGrid" />
    <h2>{`Credits`}</h2>
    <p>{`Graphic Design & Album Photography: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/sliceofdill/"
      }}>{`John Dill (Dusty Pickle)`}</a></p>
    <p>{`Live Performance Photography: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/cthelamb/"
      }}>{`Christian Lamb`}</a></p>
    <p>{`Mastering Engineer: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/audibleoddities/"
      }}>{`Shawn Hatfield, AudibleOddities`}</a></p>
    <p>{`Featured Musicians:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/nicholaspaul26/"
        }}>{`Nicholas Paul`}</a>{` (The Fireship, Sun Cellar, Alaya)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/luigisambuy/"
        }}>{`Luigi Sambuy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/_sappas/"
        }}>{`Mehdi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/girltime69/"
        }}>{`Girl Time`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/lwcassid/"
        }}>{`Lance Cassidy`}</a></li>
    </ul>
    <p>{`© Studio Collective, San Francisco.`}</p>
    <h2>{`Studio Collective Label`}</h2>
    <p>{`The album will be the first release on the Studio Collective label (SC001). The label will center around vinyl prints, audio recordings, and video recordings of live-electronic performances to support and inspire more artists to consider live-electronic as a medium.`}</p>
    <p>{`The Studio Collective is a loosely affiliated group of musicians and visual artists who live in or frequent San Francisco. Our goal is to foster a diverse community of producers and instrumentalists, with an emphasis on exceptional sound quality and musicianship. The collective serves as an umbrella for live performances, experimental events, collaborative studio recording, and audio-visual projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      