import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid} from '@material-ui/core';
import propTypes from 'helpers/propTypes';

const isHeader = node => node.props.originalType === 'h1';

const splitChildren = children => {
  if (Array.isArray(children) && isHeader(children[0])) {
    const [title, ...body] = children;
    return [title, body];
  }
  return [null, children];
};

const TextContent = ({children}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [title, body] = splitChildren(children);

  return (
    <Grid container spacing={isMd ? 8 : 4}>
      <Grid item style={{maxWidth: "100%"}}>
        <div>
          {title}
        </div>
        {body}
      </Grid>
    </Grid>
  );
};

export default TextContent;
