import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Section} from 'components/organisms';
import {Comments} from 'components/atoms';
import {Box, Divider, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

import propTypes from 'helpers/propTypes';
import {NextPrev} from './components';
import {TextContent} from './contentTypes';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    width: '100%',
  },
  section: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    display: 'block',
  },
}));

const Docs = ({themeMode, pageContext, children}) => {

  const {frontmatter} = pageContext;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <TextContent children={children}/>
      </Section>
    </div>
  );
};

export default Docs;
