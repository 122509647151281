import React from 'react';
import Docs from 'views/Docs';
import DocsLayout from 'layouts/DocsLayout';
import WithLayout from 'WithLayout';
import {SEO} from 'components/atoms';

const DocsPage = ({pageContext, children}) => (
  <WithLayout component={Docs} layout={DocsLayout} pageContext={pageContext}>
    <SEO pageContext={pageContext} />
    {children}
  </WithLayout>
);

export default DocsPage;
